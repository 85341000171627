.banner-wrapper {
    background-image: url('../../assets/images/evaluation-banner.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 600px;
    background-size: cover;
    background-position: right center;
    .mobile-banner {
        display: none;
    }
    @media only screen and (max-width: 900px) {
        padding-top: 0;
        background-image: none;
        .mobile-banner {
            display: block;
        }
    }
}

.banner-container {
    display: flex;
    align-items: center;
    padding: 5rem 0;
    height: 600px;
    margin-top: 0 !important;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
        height: auto;
    }

    .evaluation-btn {
        color: #fff;
        text-transform: initial;
    }
}

.benefits-wrapper {
    background-color: #1EABBA;
    padding: 4rem 1rem;

    .benefit-item {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        padding: 3rem;

        @media only screen and (max-width: 576px) {
            flex-flow: column wrap;
            padding: 2rem;
        }

        .benefit-img {
            flex-basis: 40%;
            width: 100%;
            img {
                width: 100%;
            }
            @media only screen and (max-width: 576px) {
                flex-basis: 100%;
            }
        }
        .benefit-info {
            padding-left: 1rem;
            @media only screen and (max-width: 576px) {
                flex-basis: 100%;
                padding-left: 0;
                margin-top: 1rem;
            }
            ol {
                list-style-type:decimal
            }
            .benefit-btn {
                color: #1EABBA;
                text-transform: initial;
                &:hover {
                    background-color: #1EABBA;
                    color: #fff;
                }
            }
        }
    }
}

.tab-wrapper {
    padding: 4rem 1rem;

    .tabs {
        margin-bottom: 2rem;
        > div {
            overflow: scroll !important;
            &::-webkit-scrollbar { display: none; }
        }
        button {
            text-transform: initial;
        }
    }

    .tab-container {
        margin-top: 2rem;
    }
}

.cta-wrapper {
    padding: 4rem 1rem;
    background-color: #ebebeb;
    .cta-container {
        padding: 2rem;
        .evaluation-btn {
            text-transform: initial;
            color: #fff;
        }
    }
}

.main-content {
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
    a, a:visited {
        color: #1EABBA
    }
    p {
        margin-bottom: 1.5rem;
    }
    ul {
        padding-left: 3rem;
        &.no-bullets {
            list-style-type: none;
        }
        li {
            margin-bottom: 1rem;
            > span {
                margin-right: 1rem;
                min-width: 100px;
                display: inline-block;
            }
        }
    }
}