@import '../../styles/scss/base/mixins';
.footer-wrapper {
    padding: 1rem 1rem 3rem;
    border-top: 1px solid #e7e7e7;
    position: relative;
    background-color: #ffffff;
    z-index: 1200;
    @media only screen and (max-width: 900px) {
        z-index: 1000;
    }

    .logo {
        width: 245px;
        text-align: center;
        display: block;
        margin: auto;
        @include respond(tablet) {
            text-align: left;
            margin: 0;
        }
    }

    .citation {
        font-size: 13px;
        margin-top: 1rem;
        text-align: center;
        @include respond(tablet) {
            text-align: left;
        }
    }

    .section-heading {
        font-weight: 600;
        display: block;
        font-size: 1rem;
        text-align: center;
        @include respond(tablet) {
            text-align: left;
        }
    }

    .footer-menu {
        
        li {
            justify-content: center;
            @include respond(tablet) {
                justify-content: flex-start;
            }
            a, a:visited {
                color: #1EABBA
            }
        }
        
    }

    .social-media {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: auto;
        justify-content: center;
        max-width: 100%;
       @include respond(tablet) {
            justify-content: flex-end;
            // max-width: 300px;
        }
        a {
            flex-grow: initial;
            svg {
                font-size: 2.5rem;
            }
        }
    }
}
.copyright {
    background-color: #1EABBA;
    padding: 0.5rem 1rem;
    color: #ffffff;
    font-size: 12px;
    position: relative;
    z-index: 1200;
}

.scroll-top {
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    z-index: 2100;
}