.banner-wrapper {
    background-image: url('../../assets/images/homepage-banner.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 600px;
    background-size: cover;
    background-position: right center;
    padding-top: 1rem;
    .mobile-banner {
        display: none;
    }
    @media only screen and (max-width: 900px) {
        padding-top: 0;
        background-image: none;
        .mobile-banner {
            display: block;
        }
    }
    // &:after {
    //     content:  "";
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background-color: rgba($color: #FFFFFF, $alpha: 0.5);
    // }
}
.banner-container {
    display: flex;
    align-items: center;
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
}

.search-wrapper {
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
}

.benefits-wrapper {
    position: relative;
    padding: 0 24px;
    overflow: hidden;
    h3 {
        margin-bottom: 0.5rem;
    }
    h3, p {
        color: #FFFFFF;
    }

    ul {
        color: #FFFFFF;
        list-style-type: disc;
        padding: 1rem 0;
    }

    
    .left-bg {
        padding: 3rem 3rem 3rem calc(50% - 576px);
        @media only screen and (max-width: 900px) {
            position: relative;
        }
        &:before {
            content: "";
            background-color: #1EABBA;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            z-index: -1;
            @media only screen and (max-width: 900px) {
                left: -1.5rem;
                width: 105vw;
            }
        }
    }
    .right-bg {
        padding: 3rem calc(50% - 576px) 3rem 3rem;
        @media only screen and (max-width: 900px) {
            position: relative;
        }
        @media only screen and (max-width: 900px) {
            padding-left: 0;
        }

        &:before {
            content: "";
            background-color: #29807F;
            position: absolute;
            top: 0;
            right:0;
            width: 50%;
            height: 100%;
            z-index: -1;
            @media only screen and (max-width: 900px) {
                left: -1.5rem;
                width: 105vw;
            }
        }
    }
    .benefit-btn {
        color: #FFFFFF;
        border-color: #FFFFFF;
        border-width: 2px;
        text-transform: none;
        font-weight: 600;
    }
}

.testimonials-wrapper {
    padding: 3rem 0;
    width: 100%;
    position: relative;
    .carousel {
        width: 100%;
        max-width: 900px;
        height: 100%;
        padding: 3rem 0;
        @media only screen and (max-width: 600px) {
            width: calc(100vw - 2.5rem);
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .avatar {
                margin-bottom: 1rem;
            }
            .title {
                margin-bottom: 2rem;
                padding: 0 5rem;
            }
            .quote {
                padding: 0 5rem;
            }
        }

        
         
    }
}
