.profile-wrapper {
    max-width: 70%;
    margin: 2rem auto 0;

    .btn {
        color: #fff;
    }
}

.profile-photo {
    margin-bottom: 3rem;
}