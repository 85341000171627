.modal-wrapper {
    .header-title {
        padding: 1rem 2.5rem;
    }
}
.modal-content {
    padding-top: 2rem;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    .modal-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 1rem;
    }
    .modal-body {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 1.5rem;
    }

    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            font-size: 14px;
            color: #1EABBA;
            text-decoration: none;
        }

    }

    .btn {
        color: #ffffff;
    }

    .btn-link {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    &.login-wrapper {
        padding-top: 1rem !important;
    }

    &.registration-wrapper {
        .modal-content {
            padding-top: 0;
        }
        .modal-body {
            text-align: left;
            display: block;       
        }
        .image-upload {
            margin-bottom: 1rem;
        }
        .consent {
            display: flex;
            align-items: flex-start;
            text-align: left;
             span {
                 padding-top: 0;
             }
        }
    }
}

.modal-footer {
    padding: 1.5rem !important;
}

.forgot-password-btn {
    display: flex;
    text-align: left;
    margin-top: 1rem;
    button {
        text-transform: initial;
    }
   
}

.error-message {
    text-align: left;
}

.collaborators-field {
    > div {
        display: flex;
        align-items: center;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > span {
            display: none;
        }
        > div {
            flex: 0 0 auto;
            padding-left: 5px;
        }
    } 
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
     p{
        margin-bottom: 0.5rem;
     }
}