.upload-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .image-placeholder {
        height: 100px;
        width: 100px;
        // background-color: #454545;
        border: 1px dashed #454545;
        padding: 2px;
        border-radius: 100%;
        position: relative;
        cursor: pointer;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }
    }
}

.file-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1EABBA;
    background-color: #ffffff;
    min-width: 380px;
    min-height: 230px;
    margin: 2rem 0;
     p{
        text-align: center;
     }

    .file-inputs {
        position: relative;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 46px;
            max-width: 200px;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: #1EABBA;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;
            // padding: 1em;
            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #1EABBA;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #1EABBA;
            }
        }
    }
}

// li {
//     list-style: none;
//     margin: 1.2em 0;
//     background-color: #f55e3038;
//     border-radius: 5px;
//     display: flex;
//     align-items: center;
//     padding: 1.2em 1.5em;

//     svg {
//         color: #1EABBA;
//         &:first-child {
//             font-size: 1.2em;
//             margin-right: 0.8em;
//         }
//     }

//     p {
//         flex: 1;
//         font-size: 0.9rem;
//     }

//     .actions {
//         justify-self: flex-end;
//         cursor: pointer;

//         .fa-spinner {
//             font-size: 1.2em;
//         }
//     }
// }

.file-list {
    padding: 0;
    width: 100%;
    li {
        list-style: none;
        margin: 1.2em 0;
        background-color: #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 1rem 1.5rem;
    
        > svg {
            color: #1EABBA;
            &:first-child {
                font-size: 1.2em;
                margin-right: 0.8em;
            }
        }
    
         a {
                text-decoration: none;
                color: #000000;
                flex: 1;
            font-size: 0.9rem;
            text-align: left;
            }
        
    
        .actions {
            display: flex;
            align-items: center;
            justify-self: flex-end;
            cursor: pointer;

            svg {
                color: #1EABBA
            }

            button {
                padding: 0;
            }
    
            .fa-spinner {
                font-size: 1.2em;
            }
        }
    }
}

