//# Mixins

@mixin respond ($breakpoint) {

  @if $breakpoint==desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==laptop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==tablet {

    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code        

    @media only screen and (min-width: 900px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (min-width: 480px) {
      @content;
    }
  }
}