.dashboard-card {
    position: relative;
    background-color: #1EABBA;
    border-radius: 10px;
    margin-top: 2rem;
    
    .dashboard-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        h2 {
            color: #ffffff;
        }
        button {
            color: #ffffff;
        }
    }
    .dashboard-body {
        padding: 1.5rem;
        background-color: #ffffff;
        button {
            margin-top: 1.5rem;
            color: #ffffff;
            display: flex;
            margin: 1.5rem auto 0;
            text-transform: initial;
        }

        .empty-dashboard {
            padding: 1.5rem;
            text-align: center;
            a {
                color: #ffffff;
                margin-top: 1.5rem;
                text-transform: initial;
            }
        }
        .evaluation-items {
            display: flex;
            align-items: flex-start;
            flex-flow: row wrap;
            padding: 1.5rem;
            margin:  2rem 0;
            .evaluation-item {
                flex-basis: calc(100%/3 - 2rem);
                margin: 1rem;
                @media only screen and (max-width: 1199px) {
                    flex-basis: calc(100%/2 - 2rem);
                }
                @media only screen and (max-width: 1024px) {
                    flex-basis: 100%;
                }
                 p{
                     margin-top: 0;
                    }
            }

        }
        .documents {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -1rem;
            
            .document-item {
                flex-basis: calc(100%/3 - 1rem);
                text-align: center;
                margin: 0.5rem;
                @media only screen and (max-width: 1199px) {
                    flex-basis: calc(100%/2 - 1rem);
                }
                @media only screen and (max-width: 576px) {
                    flex-basis: calc(100%);
                }
                a {
                    font-size: 12px;
                    color: #1EABBA;
                    margin-top:0;
                }
            }
            
        }

        .team-items {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -1rem;
            
            .team-item {
                flex-basis: calc(100%/3 - 1rem);
                text-align: center;
                align-items: center;
                margin: 0.5rem;
                @media only screen and (max-width: 1199px) {
                    flex-basis: calc(100%/2 - 1rem);
                }
                @media only screen and (max-width: 576px) {
                    flex-basis: calc(100%);
                }
                .avatar {
                    margin: 0 auto 0.5rem;
                }

                button {
                    font-size: 12px;
                }
            }
            
        }
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}