.list-items {
    list-style-type: disc;
    li {
        padding: 0;
        display: list-item;
        font-weight: 400;
    }
}

.notification-bar {
    color: #fff !important;
}

.stepper {
    .step-name {
        // cursor: pointer;
        svg text {
            fill: #fff;
        }
        
    }
}

.indicators-title {
    display: flex;
    justify-content: space-between;
    h4 {
        &:last-child {
            padding-left: 0.5rem;
        }
        flex-basis: 50%;
    }
}

.indicators-column {
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem;
    > div {
        flex-basis: 50%;
        width: 50%;
        margin: 0 0 1rem;
        padding: 0 1rem;
        &:last-child {
            padding-left: 0.5rem;
        }
    }
}

.selected-values {
    height: 40px;
    > div {
        display: flex;
        width: 100%;
        position: relative;
        height: initial;
        &:after {
            content: "";
            width: 35px;
            height: 50px;
            background-color: #fff;
            position: absolute;
            top: 0;
            right: 0;
        }
        > span {
            display: none; 
        }
        > div {
            flex: 0 0 auto;
        }
    } 
} 

.plan-items {
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 1rem;
    .plan-item {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        margin-bottom: 1rem;
        
        h4 {
            flex: 1 1 40%;
            text-align: left;
        }
        .plan-field {
            flex: 1 1 60%;
            input,textarea{ 
                width: 100%
            }
        }
    }
    
    &:last-child {
        border-bottom: none;
    }
}

.add-btn {
    margin: 2rem auto !important;
    text-align: center;
    color: #fff !important;
    display: block !important;
}

.indicator {
    background-color: #1EABBA;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
}

.upload-documents, .download-report {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    flex-flow: row wrap;
    
    button,span {
        color: #fff;
    }
}

.upload-documents {
    > div {
        flex-basis: 100%;
        margin-bottom: 0;
        min-height: 180px;
    }
}

.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap !important;
    margin-bottom: 1rem;
    p {
        font-weight: 600;
    }
}

.btn-primary {
    background-color: #1EABBA;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    color: #fff !important;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    text-decoration: none;
}

.tooltip a {
    color: #1EABBA !important;
}

.pdf-container {
    position: absolute;
    left: -100vw;
    top: -1000vh;
}

.pdf-wrapper {
    // display: none;
    // position: absolute;
    // left: -100vw;
    // top: -1000vh;
}

small {
    display: block;
    font-size: 13px;
    font-weight: 400;
}

.loading {
    display: flex;
    align-items: center;
     p{
        margin-left: 0.5rem;
     }
}

.remove-btn {
    display: flex !important;
    margin-left: auto !important;
    margin-bottom: 1rem !important;
}