.discover-wrapper {
    padding: 3rem 0;

    @media only screen and (min-width: 600px) {
        padding: 5rem 0
    }

    .card-items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row wrap;
        margin: 1rem -1rem 0;
        .card-item {
            flex-basis: calc(100%/1 - 2rem);
            margin: 1rem;
            min-height: 390px;
            min-height: auto;
            h3 {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 68px;
                @media only screen and (max-width: 600px) {
                    height: auto;
                    display: block;
                    overflow: visible;
                }
            }
            h4 {
                height: 48px;
                @media only screen and (max-width: 600px) {
                    height: auto;
                }
            }
            p {
                height: 124px;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                @media only screen and (max-width: 992px) {
                    height: 62px;
                    -webkit-line-clamp: 3;
                }
                @media only screen and (max-width: 600px) {
                    height: auto;
                }
            }
            a:visited {
                color: #1EABBA;
            }
            @media only screen and (min-width: 600px) {
                flex-basis: calc(100%/2 - 2rem);
                min-height: 240px;
            }
            @media only screen and (min-width: 900px) {
                flex-basis: calc(100%/3 - 2rem);
                min-height: 280px;
            }
            @media only screen and (min-width: 1200px) {
                flex-basis: calc(100%/5 - 2rem);
                min-height: 350px;
            }
        }
    }
}