@media all {
.page-break {
    display: none;
}
}
  
@media print {
html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
}
}
  
@media print {
.page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
}
.pdf-wrapper {
    display: block;
}
}

@page {
size: auto;
margin: 20mm;
}

.pdf-wrapper {
    display: none;
  }
  
  body > .pdf-wrapper {
    display: block;
  }
  
  @media print {
    .pdf-wrapper {
      display: block;
    }
  }

.pdf-wrapper {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    position: relative;
    margin: 0 auto;
    // padding: 0.4in 0.3in;
    color: #333;
    background-color: #fff;
    // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    // display: none;
    // width: 6.2in;
    // height: 8.7in; 

    .logo {
        max-width: 200px;
        margin-bottom: 1rem;
    }

    .eval-stages {
        margin-bottom: 1rem;
        p {
            font-size: 12px;
        }
        h2 {
            font-size: 18px;
            margin-bottom: 0.5rem;
            color: #1EABBA;
        }
        h3 {
            font-size: 14px;
            margin: 0.5rem 0;
        }
        ul {
            margin-bottom: 1rem;
            margin-top: 0;
            font-size: 12px;
            list-style-type: disc;
            li {
                line-height: 20px;
            }
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            th, td {
                padding: 5px;
            }
        }
    }

   .disclaimer {
    font-size: 11px;
   }
}