.banner-wrapper {
    display: flex;
    align-items: center;
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
}

.search-wrapper {
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
}

.bookmark-container {
    // padding: 2rem;
    .bookmark-items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row wrap;
        margin: 2rem -1rem;
        > div {
            margin: 0 1rem 2rem;
            flex-basis: calc(50% - 2rem);
        }
    }
}