.evaluation-program {
    background-color: #f9f9f9;
    padding-bottom: 4rem;
    overflow:unset;
    .container {
        max-width: 100%;
        padding: 0;
        background-color: #ffffff;
        margin-bottom: 2rem;
        border-top: 1px solid #e5e5e5;
    }
}

.evaluation-header {
    padding: 3rem 0;
    > strong {
        width: 150px;
        display: inline-block;
    }
}

.evaluation-content {
    height: 100%;
    position: relative;
    .sticky-btn {
        position: absolute;
        padding-top: 2rem;
        left: -14rem;
        top: 0;
        height: 100%;

        .btn {
            position: -webkit-sticky;
            position: sticky;
            top: 1rem;
            color: #fff;
            text-transform: initial;
        }

    }
}

.btn-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    button, a {
        color: #ffffff !important;
        text-transform:initial;
        margin: 0 0.5rem;
        
    }
}

.evaluation-sidebar {
    button, a {
        margin-top: 2rem;
        color: #ffffff;
        text-transform: initial;
    }
}

.related-program {
    margin-top: 2rem;
    h3 {
        margin-bottom: 2rem;
    }
    > div {
        margin-bottom: 1.5rem;
    }
}

.table-wrapper {
    overflow-x: scroll;

    .data-table {
        table-layout: fixed; 
        margin:0 auto;
        border-collapse:collapse;
        td, th {
            border: 1px solid #000;
            border-collapse:collapse;
        }
        th, td {
            padding: 10px;
            min-width: 200px;
        }
    }
}

.social-media-accounts {
    display: flex;
    >a {
        padding: 10px 0 10px 10px;
        margin-right: 0.5rem;
        margin-top: 0;
        color: inherit;
        min-width: auto;
    }
}
