.banner-wrapper {
    background-image: url('../../assets/images/about-banner.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: right top;
    display: flex;
    align-items: center;
    
    @media only screen and (max-width: 900px) {
        padding-top: 0;
        background-image: none;
        height: auto;
        .mobile-banner {
            display: block;
        }
    }
}

.mobile-banner {
    display: none;
    @media only screen and (max-width: 900px) {
        display: block;
    }
}

.main-content {
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 0;
    }
    a, a:visited {
        color: #1EABBA
    }
    p {
        margin-bottom: 1.5rem;
    }
    ul {
        padding-left: 3rem;
        &.no-bullets {
            list-style-type: none;
        }
        li {
            margin-bottom: 1rem;
            > span {
                margin-right: 1rem;
                min-width: 100px;
                display: inline-block;
            }
        }
    }
}