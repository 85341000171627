.header {
    background-color: #ffffff;
    position: relative !important;
    z-index: 10;
}
.bookmark {
    a {
        display: flex;
        align-items: center;
    }
}
.logo {
    img {
        width: 245px;
        margin: auto;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: translateZ(0);
        @media only screen and (max-width: 600px ) {
            width: 200px;
            image-rendering: pixelated;
            image-rendering: -moz-crisp-edges;         /* Firefox */
            image-rendering:   -o-crisp-edges;         /* Opera */
            image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
        }
    }
    
}

.default-avatar {
    background-image: url('../../assets/images/avatar-icon.svg');
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}

.nav-links {
    a {
        text-decoration: none;
        color: #000000;
        padding-right: 2rem;
        font-weight: 500;
        margin: 1rem 0;
    }
}

.toggle-menu {
    a {
        text-decoration: none;
    }
}