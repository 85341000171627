.search-form-wrapper {
    position: relative;
    margin-top: 1rem;
    .search-form {
        position: relative;
        .search-field {
            width: 100%;
            border-radius: 60px;
            border: 1px solid #e7e7e7;
            padding: 0.25rem 1rem;
            background-color: #FFFFFF;
        }
        .search-btn {
            position: absolute;
            right: 0;
        }
    }
    .adv-btn {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-start;
        @media only screen and (max-width: 900px) {
            justify-content: flex-end;
        }
        a {
            color: #1EABBA
        }
    }

    .search-filters {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin: 0 -0.5rem;

        h3 {
            flex: 1 1 100%;
            padding: 1rem 0.5rem;
            font-weight: 600;
        }

        .search-filter {
            flex: 0 1 calc(100%/3 - 1rem);
            margin-bottom: 1rem;
            margin: 0 0.5rem 1rem;
        }
    }

    .search-filters {
        display: flex;
        justify-content: flex-start;
        margin: 1rem 0;

        .filter {
            width: 150px;
        }
    }

    .date-filters {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin-top: 2rem;
        h3 {
            font-weight: 600;
            flex: 1 1 100%;
            margin-bottom: 0.5rem;
        }

        .date-filter {
            display: flex;
            align-items: center;
            flex: 1 1 100%
        }
        .date-range {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            h4 {
                margin-right: 1rem;
            }
        }
    }

    .advanced-search-btn {
        margin-top: 2rem;
        text-align: center;
        button {
            color: #ffffff;
            
        }
    }

    
}


