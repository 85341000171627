.card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 0.5rem;
    .title {
        font-size: 1.2rem;
        flex: 1 1 65%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 60px;
    }
    .incomplete, .complete {
        font-weight: 600;
        display: flex;
        align-items: center;
        .indicator {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 0.5rem;
            border-radius: 12px;
        }
    }
    .complete {
        .indicator {
            background-color: #00e676
            ;
        }
    }
    .incomplete {
        .indicator {
            background-color: #ff3d00
            ;
        }
    }
    .ssp {
        display: flex;
        align-items: center;
        
        .indicator {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #1EABBA;
            border-radius: 0;
            margin: 0 0.5rem;
        }
    }
    .date-published {
        flex: 1 1 100%;
        margin-top: 0.5rem;
        font-size: 14px;
    }
   
    
}

.goals, .description, .content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 72px;
}

.description, .content {
min-height: 60px;
}

strong {
    font-weight: 600;
}

.card-actions {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    flex-flow: row wrap;
    padding: 1.5rem 16px 1rem !important;
    text-align: right;
    @media only screen and (max-width: 1024px) {
        flex-flow: row wrap;
    }
    button {
        line-height: 1;
        margin: 0 5px 1rem !important;
        // color: #fff;
        min-height: 40px;
        text-transform: capitalize;
        font-size: 14px;
        padding: 0 10px;
        // &:first-child {
        //     margin-left: 0 !important;
        // }
        @media only screen and (max-width: 576px) {
            width: 100%;
        }
        &.outlined-btn {
            color: #1EABBA;
        }
        &.outlined-error-btn {
            color: #d32f2f;
        }
        &.primary-btn {
            color: #ffffff;
        }
        
        &[disabled] {
            background-color: #ebebeb;
            color: #adadad;
            border-color: #ebebeb;
            cursor: not-allowed;
        }
    }
}

a.editor-btn {
    color: #FFFFFF !important;
    text-transform: capitalize;
    margin: 0 5px 1rem;
    font-size: 14px;
    // padding: 5px 10px;
    height: 40px;
    // border: 1px solid #1EABBA;
}
