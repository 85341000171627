.search-wrapper {
    padding: 2rem 2rem 4rem;

    .search-card {
        padding: 2.5rem;
    }

    .back-btn {
        margin-top: 4rem;
        a {
            display: flex;
            align-items: center;
            text-decoration: none;
        }
        
    }
}

.search-form-wrapper {
    width: 100%;
    .search-filters {
        justify-content: space-between !important;
        display: flex;
        margin-top: 2rem;
        .filter {
            flex-basis: 20%;
        }
    }
}



.search-results {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 2rem -1rem 0;
    > div {
        margin: 0 1rem 2rem;
        flex-basis: calc(50% - 2rem);
        @media only screen and (max-width: 768px) {
            flex-basis: 100%;
        }

        > div:last-child {
            flex-flow: row wrap;
        }
    }
}


.evaluation-details-wrapper {
    padding: 2rem;
    .close-drawer {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }
    .evaluation-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        flex-flow: row wrap;
        h2 {
            flex: 1 1 100%;
            &.card-title {
                flex-basis: 70%;
            }
        }
        .complete, .ssp, .incomplete {
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-left: 1rem;
            flex-flow: row wrap;
            .indicator {
                width: 12px;
                height: 12px;
                display: block;
                margin-right: 0.5rem;
                border-radius: 12px;
            }
        }
        .complete {
            margin-left: 0;
            .indicator {
                background-color: #00e676
                ;
            }
        }
        .incomplete {
            margin-left: 0;
            .indicator {
                background-color: #ff3d00;
            }
        }

        .ssp {
            .indicator {
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #1EABBA;
                border-radius: 0;
            }
        }
    }
    .evaluation-details {
        margin-top: 1rem;

        .btn-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
            button,a  {
                color: #fff;
                text-decoration: none;
                text-transform: capitalize;
            }
        }
    }
    .related-programs {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        margin: 0 -0.5rem;
        > h3 {
            flex: 1 1 100%;
            padding: 0 0.5rem;
        }
        > div {
            margin: 0 0.5rem 0.5rem;
            flex-basis: calc(100%/3 - 1rem);
        }

    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}