.logo {
    img {
        width: 245px;
        margin: auto;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: translateZ(0);
        @media only screen and (max-width: 600px ) {
            width: 200px;
            image-rendering: pixelated;
            image-rendering: -moz-crisp-edges;         /* Firefox */
            image-rendering:   -o-crisp-edges;         /* Opera */
            image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
        }
    }
    
}

#dashboard-header {
    width: calc(100% - 300px);
    margin-left: 300px;
    z-index: 1201;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    box-shadow: none;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
}

.dashboard-sidebar {
    width: 300px;
    z-index: 1;
}

.drawer-links {
    a {
        display: block;
        padding: 0.5rem 1rem;
        text-decoration: none;
    }
}