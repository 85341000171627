.main-content {
    padding: 5rem 0;
    @media only screen and (max-width: 900px) {
        padding: 3rem 0;
    }
    a, a:visited {
        color: #1EABBA
    }
    p {
        margin-bottom: 1.5rem;
    }
    ul {
        padding-left: 3rem;
        &.no-bullets {
            list-style-type: none;
        }
        &.alphabet-bullets {
            list-style-type:lower-alpha;
        }
        li {
            margin-bottom: 1rem;
            > span {
                margin-right: 1rem;
                min-width: 100px;
                display: inline-block;
            }
            a {

            }
        }
    }
}