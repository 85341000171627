@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  margin: 0;
  font-family: 'Montserrat','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 16px; */
}

a, a:visited {
  color: #000000;
}

ul, ol {
  padding-left: 2rem;
  margin: 1rem 0;
}
li {
  line-height: 2rem;
}

img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-wrapper {
  /* overflow: hidden; */
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #1EABBA;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #1EABBA;
}

input {
  font-size: 16px;
}

.dashboard-container {
  min-height: 70vh;
  background-color: #f9f9f9;
  padding: 96px 32px 64px;
  margin-left: 300px;
}

@media only screen and (max-width: 576px) {
  main {
    padding: 0
  }
}

.multi-checkboxes {
  white-space: break-spaces !important;
  flex-flow: row wrap;

}

.multi-checkboxes[aria-selected=true] > span {
  color: #1EABBA;
  /* background-color: #1EABBA; */
}

.multi-checkboxes[aria-selected=true] > span > svg path {
  fill: #1EABBA;
}

.multi-checkboxes span + div {
  flex-basis: calc(100% - 46px);
}

.multi-checkboxes .checkbox-description {
  font-size: 13px;
  display: block;
  flex-basis: 100%;
}

.multi-checkboxes .checkbox-description a {
  color: #1EABBA;
  font-weight: 700;
}