.resources-item {
    background-color: #ffffff;
    
    .resource-item {
        border-radius: 8px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
        margin-bottom: 1rem;
        padding: 1rem;
        font-weight: 600;
        font-size: 16px;
        @media only screen and (max-width: 576px) {
            display: flex;
            flex-flow: column wrap;
        }
        a {
            font-weight: 500;
            text-decoration: none;
            font-size: 16px;
            cursor: pointer;
            display: block;
            &:visited {
                color: #1EABBA;
            }
            @media only screen and (max-width: 576px) {
                margin-top: 0.5rem;
            }
        }
        > div {
            @media only screen and (max-width: 576px) {
                position: relative;
                right: 0;
                top: 0;
                transform: none;
            }
        }
    }
}